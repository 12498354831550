<template>
  <div id="gridContainer">
    <DxToolbar>
      <DxItem :options="selectBoxOptionsYear"
              location="center"
              locate-in-menu="auto"
              widget="dxSelectBox"/>
      <DxItem :options="selectBoxOptionsMonth"
              location="center"
              locate-in-menu="auto"
              widget="dxSelectBox"/>       
      <DxItem :options="buttonOptionsSearch"
              location="center"
              locate-in-menu="never"
              widget="dxButton"/>    
      <DxItem :options="buttonOptionsException"
              location="after"
              locate-in-menu="never"
              widget="dxButton"/> 
    </DxToolbar>
    
    <DxDataGrid
        id="grid"
        :ref="gridRefName"
        :dataSource="pmList"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :key-expr="item"
        :selection="{ mode: 'multiple' }"
        @rowDblClick="onDblClick"
        @rowClick="onClick">
        
        <DxExport :enabled="true"/>
        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxSorting mode="multiple"/>
        <DxScrolling mode="infinite"/>
        <DxColumn
          data-field="planDate"
          caption="날짜">
        </DxColumn>
        
        <DxColumn
          data-field="equipmentId"
          caption="장비 코드">  
          <DxLookup
            :data-source="equipmentList"
            value-expr="equipmentId"
            display-expr="nameCode"
            :header-filter="{ allowSearch: true }"/>
        </DxColumn>

        <DxColumn
          data-field="item"
          caption="PM 항목"
          :header-filter="{ allowSearch: true }">
        </DxColumn>
  
        <DxColumn
          data-field="cycle"
          caption="주기">
          <DxLookup
            :data-source="pmCycle"
            value-expr="ID"
            display-expr="Name"/>          
        </DxColumn>

        <DxColumn
          data-field="tolerance"
          caption="Tolerance">
          <DxLookup
            :data-source="dayTolerance"
            value-expr="ID"
            display-expr="Name"/>          
        </DxColumn>

        <DxColumn
          data-field="method"
          caption="점검 방법"
          :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
          data-field="spec"
          caption="Spec"
          :header-filter="{ allowSearch: true }">
        </DxColumn>       
        
        <DxColumn
          data-field="reActionPlan"
          caption="Re-Action Plan"
          :header-filter="{ allowSearch: true }">
        </DxColumn>   

        <DxColumn
          data-field="inspMethod"
          caption="결과 입력 방법">
        </DxColumn>

        <DxColumn
          data-field="result"
          caption="결과">
        </DxColumn>

        <DxColumn
          data-field="unit"
          caption="단위"
          alignment="center">  
        </DxColumn>
        
        <DxColumn
          data-field="lsl"
          caption="LSL"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="usl"
          caption="USL"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="judgement"
          caption="판정"
          alignment="center"> 
       </DxColumn>        
       
       <DxColumn
          data-field="userCmt"
          caption="비고"
          alignment="center">  
       </DxColumn> 
      </DxDataGrid>
      
      <DxPopup :ref="popupNumberRefName"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-title="true"
                :width="300"
                :height="240"
                title="결과 입력">
                <div class='Item'>장비: {{currentRow.equipmentId}}</div>
                <div class='Item'>항목: {{currentRow.item}}</div>
                <DxNumberBox :value="numberValue"
                             @valueChanged="setNumberValue"/> 
                <div class='ButtonBox'>
                  <DxButton class='Button'
                      :width="120"
                      text="입력"
                      type="success"
                      styling-mode="contained"
                      @click="setResult('MEASURE')"/>
                </div>
      </DxPopup>
      
      <DxPopup :ref="popupPassFailRefName"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-title="true"
                :width="300"
                :height="270"
                title="결과 입력">
                <div class='Item'>장비: {{currentRow.equipmentId}}</div>
                <div class='Item'>항목 : {{currentRow.item}}</div>
                <div class='ButtonBox'>
                  <DxButton class='Button'
                    :width="120"
                    text="Pass"
                    type="success"
                    styling-mode="contained"
                    @click="setResult('PASS')"/>
                  <DxButton class='Button'
                    :width="120"
                    text="Fail"
                    type="danger"
                    styling-mode="contained"
                    @click="setResult('FAIL')"/>
                </div>
       </DxPopup>   

       <DxPopup :ref="popupExceptionRefName"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-title="true"
                :width="400"
                :height="350"
                title="예외 사유 입력">
                <div class='Item'>선택된 항목에 대 일괄 예외처리</div>
                <DxTextArea
                  :value="exceptionValue"
                  :height="150"
                  :max-length="100"
                  @value-changed="setExceptionValue"/>                
                <div class='ButtonBox'>
                  <DxButton class='Button'
                      :width="120"
                      text="입력"
                      type="success"
                      styling-mode="contained"
                      @click="setResultException()"/>
                </div>
      </DxPopup>
              
  </div>
</template>

<script>
  import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
  import {DxDataGrid,
          DxColumn,
          DxSorting,
          DxLookup,
          DxSearchPanel,
          DxHeaderFilter,
          DxScrolling,
          DxExport} from "devextreme-vue/data-grid";
  import DxPopup from "devextreme-vue/popup";
  import {DxNumberBox} from "devextreme-vue/number-box";
  import DxButton from "devextreme-vue/button";
  import {DxTextArea} from "devextreme-vue/text-area";
  import {month,
          pmCycle,
          dayTolerance} from "../../utils/syscodedata";
  import fn from "../../utils/function";
  import dsu from "../../utils/dataSourceUtils";
  var date = new Date();
  var defaultYear = '2024';
  var defaultMonth = date.toISOString().substring(5, 7);

  export default {
    components: {
      DxToolbar,
      DxItem,
      DxDataGrid,
      DxColumn,
      DxSorting,
      DxLookup,
      DxSearchPanel,
      DxHeaderFilter,
      DxScrolling,
      DxPopup,
      DxButton,
      DxExport,
      DxNumberBox,
      DxTextArea
    },
    data() {
      return {
        gridRefName: 'dataGrid',
        yearList: [],
        pmList: {},
        equipmentList: [],
        pmCycle: pmCycle,
        dayTolerance: dayTolerance,
        searchCondition: {year: defaultYear, month: defaultMonth},
        popupNumberRefName: 'popupNumber',
        popupPassFailRefName: 'popupPassFail',
        popupExceptionRefName: 'popupException',
        currentRow:{},
        numberValue: '',
        exceptionValue: '',
        selectBoxOptionsYear: {
          width: 120,
          items: this.getYearListWithNames(2021),
          valueExpr: 'ID',
          displayExpr: 'Name',
          value: defaultYear,
          onValueChanged: (args) => {
            this.searchCondition.year = args.value;
          }
        }, 
        selectBoxOptionsMonth: {
          width: 90,
          items: month,
          valueExpr: 'ID',
          displayExpr: 'Name',
          value: defaultMonth,
          onValueChanged: (args) => {
            this.searchCondition.month = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        },
        buttonOptionsException: {
          icon: 'exception',
          text: '예외처리',
          stylingMode: "outlined",
          onClick: this.showException.bind()
        }       
      };
    },
    async mounted(){
      await dsu.getAxiosEquipmentList().then(res => {this.equipmentList = res.data.data});
      await this.search();
    },

    computed: {
      gridPMList: function () {return this.$refs[this.gridRefName].instance;}
    },    
    methods: {
      search(){
        var params = JSON.parse(JSON.stringify(this.searchCondition));
        dsu.getAxiosOBJGet('/api/mes/EEPM/selectPmList', params).then(res =>{
            let data = res.data.data;
            if(res.data.result == 'SUCCESS'){
                if(data.length == 0){
                  fn.notifyError('데이터 없음');
                  this.pmList = [];
                }else{
                  this.pmList = data;
                }
            }else{
              fn.notifyError(res.data.msg);
            }
        });
      },
      showException(){
        var selectedRows = this.gridPMList.getSelectedRowsData();

        if(selectedRows?.length){
          
          this.$refs[this.popupExceptionRefName].instance.show();
        }else{
          fn.notifyError("PM 항목 선택 후 진행 필요");
        }
      },
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      onClick(e){
        this.currentRow = e.data;
      },
      onDblClick(e){
        this.currentRow = e.data;
        if(this.currentRow.inspMethod == 'PASS/FAIL'){
          this.$refs[this.popupPassFailRefName].instance.show();
        }else{
          this.$refs[this.popupNumberRefName].instance.show();
        }
      },
      setResultException(){
        var params = {};

        if(!this.exceptionValue){
          fn.notifyError("예외처리 사유 입력 필요");
          return;
        }

        params.pmExceptionList = JSON.stringify(this.gridPMList.getSelectedRowsData());
        params.userCmt = this.exceptionValue;

        dsu.getAxiosOBJPost(`/api/mes/EEPM/updatePmResultException`, params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
            this.gridPMList.clearFilter();
            this.$refs[this.popupExceptionRefName].instance.hide();
            this.search();
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      setResult(result){
        let finalResult = '';

        if(this.currentRow.operation == '9000'){
            fn.notifyError('장비가 현장에 있으니 입고 시 점검 필요');
            return;
        } 

        if(result == 'MEASURE'){
          finalResult = this.numberValue;
          this.numberValue ='';
        }else{
          finalResult = result;
        }
        
        for (var i = 0; i < this.pmList.length; i++) {
          if(this.pmList[i].equipmentId == this.currentRow.equipmentId && this.pmList[i].item == this.currentRow.item){
            this.pmList[i].result = finalResult;
            this.pmList[i].userCmt = '';

            if(this.pmList[i].inspMethod == 'PASS/FAIL'){
              this.pmList[i].judgement = finalResult;
            }else if(this.pmList[i].inspMethod == 'MEASURE'){
              if(finalResult){
                if(this.pmList[i].lsl < finalResult && this.pmList[i].usl > finalResult){
                  this.pmList[i].judgement = 'PASS';
                }else{
                  this.pmList[i].judgement = 'FAIL';
                }
              }else{
                this.pmList[i].judgement = '';
              }
            }
            
            dsu.getAxiosOBJPost(`/api/mes/EEPM/updatePmResult`,this.pmList[i]).then(res=>{
              let response = res.data;
              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
                this.$refs[this.popupPassFailRefName].instance.hide();
                this.$refs[this.popupNumberRefName].instance.hide();
                this.search();
              }else{
                fn.notifyError(response.msg);
              }
            });

          }
        }
      },
      setNumberValue(e){
        this.numberValue = e.value;
      },
      setExceptionValue(e){
        this.exceptionValue = e.value;
      },
      getYearListWithNames(startYear) {
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;

        return Array.from({ length: nextYear - startYear + 1 }, (_, i) => {
          const year = nextYear - i; 
          return { ID: String(year), Name: `${year}년` };
        });
      }
    }   
  };
</script>

<style scoped>
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;}
  
  #grid {
    width: 100%;
    height: calc( 100vh - 100px )}
  
  #popup {
    width: 100%;
    height: 100%}
  
  .Item{
    margin-bottom: 15px;
  }

  .ButtonBox{
    text-align: center;
  }

  .Button{
    margin: 7px;
  }    
</style>